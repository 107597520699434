import { useMutation, UseMutationOptions } from 'react-query';
import { TaskTemplateAPI } from 'api';

export const useAddTaskTemplateDirectory = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.addTaskTemplateDirectory, { ...config });
};

export const useDeleteTaskTemplateDirectory = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.deleteTaskTemplateDirectory, { ...config });
};

export const useUpdateTaskTemplateDirectory = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.updateTaskTemplateDirectory, { ...config });
};

export const useUpdateTaskTemplate = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.updateTaskTemplate, { ...config });
};

export const useDeleteTaskTemplate = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.deleteTaskTemplate, { ...config });
};

export const useDeleteWipTaskTemplate = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.deleteWipTaskTemplate, { ...config });
};

export const useCreateTemplateTask = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.createNewTemplateTask, { ...config });
};

export const useUpdateTemplateTaskDependencies = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.updateTemplateTaskDependencies, { ...config });
};

export const useDeleteTemplateTask = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.deleteTemplateTask, { ...config });
};

export const useCreateTemplate = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.createNewTemplate, { ...config });
};

export const useCreateWipTemplate = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.createWipTaskTemplate, { ...config });
};

export const useSaveTemplateAsCopy = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.saveAsCopyTemplate, { ...config });
};

export const useUpdateTemplateTaskName = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.updateTemplateTaskName, { ...config });
};

export const useUpdateTemplateTaskDuration = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.updateTemplateTaskDuration, { ...config });
};

export const useUpdateTemplateTaskType = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.updateTemplateTaskWorkItemType, { ...config });
};

export const useUpdateTemplateTaskMilestone = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.updateTemplateTaskMilestone, { ...config });
};

export const useIndentTemplateTask = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.indentTemplateTask, { ...config });
};

export const useOutdentTemplateTask = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.outdentTemplateTask, { ...config });
};

export const usePasteTemplateTask = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.pasteTemplateTask, { ...config });
};

export const useMoveTemplateTask = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.moveTemplateTask, { ...config });
};

export const useReplaceWipTemplate = (config?: UseMutationOptions) => {
  return useMutation(TaskTemplateAPI.replaceWipTaskTemplate, { ...config });
};
