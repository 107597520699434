import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PinIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6126 8.21393C17.8714 8.32469 17.9917 8.19726 18.1751 7.99242C18.424 7.71017 18.4621 7.67206 18.181 7.39101C16.4923 5.70229 14.8036 4.01357 13.1149 2.32485C12.8338 2.04379 12.7957 2.0819 12.5135 2.3308C12.3074 2.51301 12.1812 2.63449 12.292 3.89329C12.4623 5.84758 9.9923 8.31754 8.03801 8.14724C6.77921 8.03649 6.65774 8.16272 6.47553 8.36875C6.22662 8.651 6.18852 8.68911 6.46957 8.97016L8.61322 11.1138L4.34617 15.3809C3.83407 15.893 4.61293 16.6718 5.12503 16.1597L9.39208 11.8927L11.5357 14.0363C11.8168 14.3174 11.8549 14.2793 12.1371 14.0304C12.342 13.847 12.4694 13.7267 12.3587 12.4679C12.1884 10.5136 14.6583 8.04363 16.6126 8.21393Z"
      fill="#007983"
    />
  </SvgIcon>
);

export default PinIcon;
