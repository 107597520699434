import { getEnv } from 'services/apiEnv.service';
import { get, post, del, put, patch } from 'services/request.service';
import {
  AddTaskTemplateDirectoryType,
  DeleteTaskTemplateDirectory,
  GetTaskTemplateLibraryType,
  TaskTemplateLibraryFilterEnums,
  TaskTeplateDirectoryType,
  TemplateCreateDTO,
  TemplateSaveAsDTO,
  TemplateTaskDeleteDTO,
  TemplateTaskType,
  TemplateTaskUpdateDependencyDTO,
  TemplateUpdateDTO,
  UpdateTaskTemplateDirectory
} from './task-template.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/task-template-management`;

export const getTaskTemplateLibrary = async ({
  params
}: GetTaskTemplateLibraryType): Promise<TaskTeplateDirectoryType[]> => {
  return (
    await get({
      url: `${BASE_URL}/library`,
      params: { ...params }
    })
  ).data;
};

export const getTaskTemplateLibraryEnums = async (): Promise<TaskTemplateLibraryFilterEnums> => {
  return (
    await get({
      url: `${BASE_URL}/library/filter-enums`
    })
  ).data;
};

export const getTaskTemplateDirectory = async ({
  directoryId
}: {
  directoryId: number;
}): Promise<TaskTeplateDirectoryType> => {
  return (
    await get({
      url: `${BASE_URL}/directories/${directoryId}`
    })
  ).data;
};

export const updateTaskTemplateDirectory = async ({ data }: UpdateTaskTemplateDirectory) => {
  return await put({
    url: `${BASE_URL}/directories/${data.directoryId}`,
    method: 'PUT',
    data
  });
};

export const deleteTaskTemplateDirectory = async ({ data }: DeleteTaskTemplateDirectory) => {
  return await del({
    url: `${BASE_URL}/directories/${data.directoryId}`,
    method: 'DELETE',
    data
  });
};

export const getTaskTemplate = async ({ taskTemplateId }: { taskTemplateId: number }) => {
  return (
    await get({
      url: `${BASE_URL}/templates/${taskTemplateId}`
    })
  ).data;
};

export const updateTaskTemplate = async ({
  taskTemplateId,
  data
}: {
  taskTemplateId: number;
  data: TemplateUpdateDTO;
}) => {
  return (
    await put({
      url: `${BASE_URL}/templates/${taskTemplateId}`,
      data
    })
  ).data;
};

export const deleteTaskTemplate = async ({
  taskTemplateId,
  data
}: {
  taskTemplateId: number;
  data: {
    templateId: number;
    rowVersion: string;
    versionRowVersion: string;
  };
}) => {
  return (
    await del({
      url: `${BASE_URL}/templates/${taskTemplateId}`,
      data
    })
  ).data;
};

export const getTaskTemplateCollection = async ({
  taskTemplateId
}: {
  taskTemplateId: number;
}): Promise<TemplateTaskType> => {
  return (
    await get({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks`
    })
  ).data;
};

export const createNewTemplateTask = async ({
  taskTemplateId,
  data
}: {
  taskTemplateId: number;
  data: TemplateCreateDTO;
}) => {
  return (
    await post({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks`,
      data
    })
  ).data;
};

export const updateTemplateTaskDependencies = async ({
  taskTemplateId,
  taskId,
  data
}: {
  taskTemplateId: number;
  taskId: number;
  data: TemplateTaskUpdateDependencyDTO;
}) => {
  return (
    await put({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}`,
      data
    })
  ).data;
};

export const deleteTemplateTask = async ({
  taskTemplateId,
  taskId,
  data
}: {
  taskTemplateId: number;
  taskId: number;
  data: TemplateTaskDeleteDTO;
}) => {
  return (
    await del({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}`,
      data
    })
  ).data;
};

export const getTemplateDependencyCandidates = async ({
  taskTemplateId,
  taskId,
  input
}: {
  taskTemplateId: number;
  taskId: number;
  input: string;
}) => {
  return await get({
    url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}/dependency-candidates`,
    params: { searchTerm: input }
  });
};

export const addTaskTemplateDirectory = async ({ data }: AddTaskTemplateDirectoryType) => {
  return await post({
    url: `${BASE_URL}/directories`,
    method: 'POST',
    data
  });
};

export const createNewTemplate = async ({ data }: { data: { requestId: number | null } }) => {
  return (
    await post({
      url: `${BASE_URL}/templates`,
      data
    })
  ).data;
};

export const createWipTaskTemplate = async ({
  taskTemplateId,
  data
}: {
  taskTemplateId: number;
  data: { templateId: number };
}) => {
  return (
    await post({
      url: `${BASE_URL}/templates/${taskTemplateId}/wip`,
      data
    })
  ).data;
};

export const saveAsCopyTemplate = async ({
  taskTemplateId,
  data
}: {
  taskTemplateId: number;
  data: TemplateSaveAsDTO;
}) => {
  return (
    await post({
      url: `${BASE_URL}/templates/${taskTemplateId}/save-as`,
      data
    })
  ).data;
};

export const updateTemplateTaskName = async ({ taskTemplateId, taskId, data, headers }: any) => {
  return (
    await patch({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}/Name`,
      data,
      headers
    })
  ).data;
};

export const updateTemplateTaskDuration = async ({
  taskTemplateId,
  taskId,
  data,
  headers
}: any) => {
  return (
    await patch({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}/Duration`,
      data,
      headers
    })
  ).data;
};

export const updateTemplateTaskWorkItemType = async ({
  taskTemplateId,
  taskId,
  data,
  headers
}: any) => {
  return (
    await patch({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}/TaskWorkItemType`,
      data,
      headers
    })
  ).data;
};

export const updateTemplateTaskMilestone = async ({
  taskTemplateId,
  taskId,
  data,
  headers
}: any) => {
  return (
    await patch({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}/milestone`,
      data,
      headers
    })
  ).data;
};

export const indentTemplateTask = async ({ taskTemplateId, taskId, data }: any) => {
  return (
    await put({ url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}/indent`, data })
  ).data;
};

export const outdentTemplateTask = async ({ taskTemplateId, taskId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/templates/${taskTemplateId}/tasks/${taskId}/outdent`,
      data
    })
  ).data;
};

export const pasteTemplateTask = async ({ taskTemplateId, data }: any) => {
  return (await put({ url: `${BASE_URL}/templates/${taskTemplateId}/tasks/paste`, data })).data;
};

export const moveTemplateTask = async ({ taskTemplateId, data }: any) => {
  return (await put({ url: `${BASE_URL}/templates/${taskTemplateId}/tasks/move`, data })).data;
};

export const deleteWipTaskTemplate = async ({
  taskTemplateId,
  data
}: {
  taskTemplateId: number;
  data: { templateId: number; versionRowVersion: string; templateRowVersion: string };
}) => {
  return (
    await del({
      url: `${BASE_URL}/templates/${taskTemplateId}/wip`,
      data
    })
  ).data;
};

export const replaceWipTaskTemplate = async ({
  taskTemplateId,
  data
}: {
  taskTemplateId: number;
  data: { templateId: number; versionRowVersion: string };
}) => {
  return (
    await post({
      url: `${BASE_URL}/templates/${taskTemplateId}/wip-replace`,
      data
    })
  ).data;
};

export const getTaskTemplateByEntityId = async ({ entityId }: { entityId: number }) => {
  return (
    await get({
      url: `${BASE_URL}/templates-by-entity/${entityId}`
    })
  ).data;
};
