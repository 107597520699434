import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';
import { AuditLogAPI } from 'api';
import { TQuery, TInfiniteQuery } from 'api/api.types';
import { mapToOptions, reduceToOptions } from 'api/api.utils';

export const useRequestAuditLog = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await AuditLogAPI.getRequestAuditLog(args, pageParam);

    queryClient.setQueryData(['audit-log-headers'], headers);

    return items;
  };

  return useInfiniteQuery(['audit-log', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true,
    cacheTime: 0
  });
};

export const useAuditLogFilterEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await AuditLogAPI.getAuditLogFilterEnums());

  return useQuery(['log-filter-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useGetProgramsAuditLog = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await AuditLogAPI.getProgramAuditLog(args, pageParam);

    queryClient.setQueryData(['programs-audit-log-headers'], headers);

    return items;
  };

  return useInfiniteQuery(['programs-audit-log', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true,
    cacheTime: 0
  });
};

export const useGetProgramsAuditLogFilterEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await AuditLogAPI.getProgramsAuditLogFilterEnums());

  return useQuery(['programs-log-filter-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useGetProgramsAuditLogFilterDefaults = (config: TQuery = {}) => {
  const queryFn = async () =>
    reduceToOptions(await AuditLogAPI.getProgramsAuditLogFilterDefaults());

  return useQuery(['programs-log-filter-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useGetAdminAuditLog = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await AuditLogAPI.getAdminAuditLog(args, pageParam);

    queryClient.setQueryData(['admin-audit-log-headers'], headers);

    return items;
  };

  return useInfiniteQuery(['admin-audit-log', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true,
    cacheTime: 0
  });
};

export const useGetAdminAuditLogFilterEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await AuditLogAPI.getAdminAuditLogFilterEnums());

  return useQuery(['admin-log-filter-enums'], queryFn, { staleTime: Infinity, ...config });
};
