import { get } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';
import { AdminAuditLog } from './audit-log.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/audit-log-management`;

export const getRequestAuditLog = async ({ params }: TAPIArgs = {}, pageNumber = 1) => {
  const response = await get({
    url: `${BASE_URL}/requests/audit-logs`,
    params: { ...params, pageNumber }
  });
  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata),
    filterCount: JSON.parse(response.headers.filtercount)
  };
};

export const getAuditLogFilterEnums = async () => {
  return (await get({ url: `${BASE_URL}/requests/filter-enums` })).data;
};

export const logSearchTeamMembers = (input: string) => {
  return get({
    url: `${BASE_URL}/team-member-search`,
    params: { searchTerm: input }
  });
};

export const getProgramAuditLog = async ({ params }: TAPIArgs = {}, pageNumber = 1) => {
  const response = await get({
    url: `${BASE_URL}/programs/audit-logs`,
    params: { ...params, pageNumber }
  });
  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata),
    filterCount: JSON.parse(response.headers.filtercount)
  };
};

export const getProgramsAuditLogFilterEnums = async () => {
  return (await get({ url: `${BASE_URL}/requests/filter-enums` })).data;
};

export const getProgramsAuditLogFilterDefaults = async () => {
  return (await get({ url: `${BASE_URL}/requests/filter-defaults` })).data;
};

export const getAdminAuditLog = async ({ params }: TAPIArgs = {}, pageNumber = 1) => {
  const response = await get({
    url: `${BASE_URL}/admin/audit-logs`,
    params: { ...params, pageNumber }
  });
  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata),
    filterCount: JSON.parse(response.headers.filtercount)
  };
};

export const getAdminAuditLogFilterEnums = async () => {
  return (await get({ url: `${BASE_URL}/admin/filter-enums` })).data;
};
