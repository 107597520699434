import { useMutation } from 'react-query';
import { TeamPlannerAPI } from 'api';

export const useUnAssignTeamMember = (config?: any) => {
  return useMutation(TeamPlannerAPI.unAssignTeamMember, { ...config });
};

export const useAssignTeamMembers = (config?: any) => {
  return useMutation(TeamPlannerAPI.assignTeamMembers, {
    ...config
  });
};

export const useEditTeamMembers = (config?: any) => {
  return useMutation(TeamPlannerAPI.editTeamMembers, {
    ...config
  });
};

export const useMarkWorkComplete = (config?: any) => {
  return useMutation(TeamPlannerAPI.markWorkComplete, {
    ...config
  });
};

export const useAddOverrides = (config?: any) => {
  return useMutation(TeamPlannerAPI.addOverrides, {
    ...config
  });
};

export const useUpdateOverrides = (config?: any) => {
  return useMutation(TeamPlannerAPI.updateOverrides, {
    ...config
  });
};

export const useDeleteOverrides = (config?: any) => {
  return useMutation(TeamPlannerAPI.deleteOverride, {
    ...config
  });
};

export const useDeleteAllOverrides = (config?: any) => {
  return useMutation(TeamPlannerAPI.deleteAllOverride, {
    ...config
  });
};
