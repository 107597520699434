import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ResetIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      d="M7.00059 0.000976662C8.25031 0.0053528 9.47634 0.34238 10.5527 0.977429C11.629 1.61248 12.5169 2.52265 13.1251 3.61442V1.40086C13.1251 1.28484 13.1712 1.17357 13.2532 1.09153C13.3352 1.00949 13.4465 0.963395 13.5625 0.963395C13.6786 0.963395 13.7898 1.00949 13.8719 1.09153C13.9539 1.17357 14 1.28484 14 1.40086V5.25053H10.1503C10.0343 5.25053 9.92303 5.20444 9.84099 5.1224C9.75895 5.04036 9.71286 4.92909 9.71286 4.81307C9.71286 4.69705 9.75895 4.58578 9.84099 4.50374C9.92303 4.4217 10.0343 4.37561 10.1503 4.37561H12.5345C11.9828 3.21116 11.0778 2.25041 9.94842 1.63006C8.81903 1.00971 7.52276 0.761371 6.24406 0.920389C4.96537 1.07941 3.7694 1.63768 2.82636 2.51575C1.88332 3.39383 1.24125 4.54697 0.991517 5.81108C0.741787 7.07519 0.897123 8.38587 1.43542 9.55659C1.97371 10.7273 2.86754 11.6984 3.98971 12.3317C5.11188 12.9651 6.4052 13.2283 7.68564 13.084C8.96609 12.9397 10.1684 12.3953 11.1215 11.5281C11.164 11.4893 11.2137 11.4593 11.2679 11.4398C11.322 11.4202 11.3794 11.4115 11.4369 11.4141C11.4944 11.4168 11.5508 11.4307 11.6029 11.4552C11.655 11.4796 11.7017 11.5141 11.7405 11.5566C11.7793 11.5991 11.8093 11.6488 11.8289 11.7029C11.8484 11.757 11.8571 11.8145 11.8545 11.872C11.8518 11.9294 11.8379 11.9858 11.8135 12.0379C11.789 12.09 11.7546 12.1368 11.7121 12.1756C10.8712 12.9413 9.85641 13.4906 8.75551 13.7759C7.65462 14.0612 6.50077 14.074 5.39383 13.8132C4.28688 13.5523 3.26017 13.0256 2.40255 12.2787C1.54492 11.5318 0.882208 10.5872 0.471755 9.52654C0.0613029 8.46592 -0.0845256 7.32125 0.0468866 6.1916C0.178299 5.06195 0.582994 3.98133 1.22595 3.04325C1.86891 2.10517 2.73077 1.33789 3.73695 0.807803C4.74313 0.277714 5.86332 0.000784651 7.00059 0.000976662V0.000976662Z"
      // fill="#7D858C"
    />
  </SvgIcon>
);

export default ResetIcon;
